import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="494" height="10" viewBox="0 0 494 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M91.7991 9.31187C81.5938 8.34887 6.84106 9.13127 1.40879 8.83304C-3.02565 8.44283 4.08545 1.86067 8.20612 0.901849C9.65051 0.557048 11.3273 0.511896 15.1682 0.683905C21.2584 1.00344 100.021 0.679727 100.021 0.679727C101.06 0.886726 104.182 0.210637 104.755 0.460556C106.557 1.04529 234.219 0.690633 245.106 0.695837C251.707 0.69919 246.485 1.07566 254.253 0.943097C270.808 0.681434 306.887 1.10923 319.432 0.789428C329.471 0.5247 331.636 0.519348 341.271 0.793703C351.118 1.04335 403.477 0.909695 409.464 0.465878C410.929 0.343689 412.054 0.392022 412.053 0.538263C412.053 0.570054 448.96 0.803549 449.13 0.918575C449.405 1.06575 450.53 1.13951 451.57 1.04131C453.502 0.895276 492.788 0.475591 493.402 0.903686C495.33 2.13104 489.9 7.31371 485.333 8.5635C480.659 9.90194 455.026 8.29522 434.415 9.03905L338.463 8.79568L317.896 9.23445C315.542 8.78773 306.476 9.86327 301.003 9.05624C298.69 8.73682 282.348 8.63678 278.739 8.95515C275.597 9.19881 192 8.27256 183.423 9.12723C168.144 8.33774 106.168 9.18266 91.7991 9.31187Z" fill="#FFBA00" />
    </svg>
  </span>
)

export default titleUnderline
