import React from "react"

export const TitleUnderlineExperienceOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="206" height="9" viewBox="0 0 206 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M38.2986 9C34.0397 8.16452 2.85454 9.09727 0.587823 8.84548C-1.26274 8.50786 1.69462 2.5622 3.41237 1.68666C4.01447 1.37192 4.71396 1.32616 6.3166 1.46922C8.85781 1.73818 41.7164 1.20598 41.7164 1.20598C42.1503 1.38909 43.4519 0.77107 43.6909 0.994246C44.4436 1.51499 97.7026 0.805402 102.245 0.77679C104.999 0.759622 102.82 1.11441 106.061 0.971352C112.968 0.685228 128.02 0.959909 133.253 0.633728C137.441 0.364771 138.344 0.353335 142.364 0.570789C146.473 0.765353 168.316 0.484949 170.813 0.0672084C171.424 -0.0472411 171.893 -0.00718032 171.893 0.124437C171.893 0.153049 187.291 0.250328 187.362 0.353332C187.477 0.484949 187.946 0.547892 188.38 0.456332C189.186 0.318993 205.576 -0.178864 205.832 0.204542C206.638 1.30326 204.38 5.98424 202.476 7.12301C200.529 8.3419 189.832 6.97424 181.235 7.70672L141.204 7.78111L132.624 8.2389C131.641 7.84405 127.861 8.83976 125.576 8.13017C124.611 7.84977 117.793 7.80971 116.288 8.10728C114.978 8.33618 80.1001 7.75821 76.523 8.55363C70.1478 7.88982 44.293 8.83977 38.2986 9Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineExperienceTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="144" height="9" viewBox="0 0 144 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.7718 9C23.7947 8.16452 1.99541 9.09727 0.410905 8.84548C-0.882693 8.50786 1.18459 2.5622 2.38535 1.68666C2.80623 1.37192 3.2952 1.32616 4.41549 1.46922C6.19187 1.73818 29.161 1.20598 29.161 1.20598C29.4643 1.38909 30.3741 0.77107 30.5412 0.994246C31.0673 1.51499 68.297 0.805402 71.4722 0.77679C73.3971 0.759622 71.8745 1.11441 74.1398 0.971352C78.9676 0.685228 89.4897 0.959909 93.1477 0.633728C96.0753 0.364771 96.7066 0.353335 99.5167 0.570789C102.389 0.765353 117.658 0.484949 119.403 0.0672084C119.83 -0.0472411 120.159 -0.00718032 120.159 0.124437C120.159 0.153049 130.922 0.250328 130.972 0.353332C131.052 0.484949 131.38 0.547892 131.683 0.456332C132.247 0.318993 143.703 -0.178864 143.883 0.204542C144.446 1.30326 142.868 5.98424 141.537 7.12301C140.175 8.3419 132.698 6.97424 126.688 7.70672L98.7058 7.78111L92.7082 8.2389C92.0212 7.84405 89.3783 8.83976 87.7814 8.13017C87.1068 7.84977 82.3409 7.80971 81.2887 8.10728C80.3726 8.33618 55.9923 7.75821 53.4918 8.55363C49.0354 7.88982 30.9621 8.83977 26.7718 9Z" fill="#FFA700" />
    </svg>
  </span>
)
